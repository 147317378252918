.overflow-hidden {
    position: relative;
    overflow: hidden;
}

.content a {
    color: #00a8e3;
    text-decoration: none;
}

.content a:hover {
    text-decoration: underline;
}

.scroll-to-link {
    cursor: pointer;
}

.content p, .content ul, .content ol {
    font-size: 14px;
    color: #777A7A;
    margin-bottom: 16px;
    line-height: 1.6;
    font-weight: 300;
}

.content h1:first-child {
    font-size: 1.333em;
    color: #034c8f;
    padding-top: 2.5em;
    text-transform: uppercase;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-top-width: 0;
    margin-top: 0;
    margin-bottom: 1.3em;
    clear: both;
}

code,
pre {
    font-family: 'Source Code Pro', monospace;
}

.higlighted {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 3px;
    border-radius: 3px;
}

/* LEFT-MENU
----------------------------------------------------------------------------------------*/

.left-menu {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    box-sizing: border-box;
    background-color: #f4f5f8;
    overflow-x: hidden;
    font-size: 18px;
}

.left-menu .content-infos {
    position: relative;
    padding: 12px 13.25%;
    margin-bottom: 20px;
}

.left-menu .info {
    position: relative;
    font-size: 14px;
    margin-top: 5px;
    color: #777A7A;
}

.left-menu .info b {
    font-weight: 500;
    color: #034c8f;
}

.content-logo {
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 1.425em 11.5%;
    padding-right: 0;
}

.content-logo img {
    display: inline-block;
    max-width: 70%;
    vertical-align: middle;
}

.content-logo span {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    color: #323F4C;
    font-size: 1.1em;
}

.content-menu {
    margin: 2em auto 2em;
    padding: 0 0 100px;
    transition: all 0.3s ease;
}

.content-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 28px;
}

.content-menu ul li {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 0;
}

.content-menu ul li:hover,
.content-menu ul li.active {
    background-color: #DCDEE9;
}

.content-menu ul li:hover a,
.content-menu ul li.active a {
    color: #00a8e3;
}

@media (hover: none) {
    .content-menu ul li:not(.active):hover {
        background-color: inherit;
    }

    .content-menu ul li:not(.active):hover a {
        color: #777A7A;
    }
}

.content-menu ul li a {
    padding: 12px 13.25%;
    color: #777A7A;
    letter-spacing: 0.025em;
    line-height: 1.1;
    display: block;
    text-transform: capitalize;
}

/* CONTENT-PAGE
----------------------------------------------------------------------------------------*/

.content-page {
    position: relative;
    box-sizing: border-box;
    margin-left: 300px;
    z-index: 2;
    background-color: #fff;
    min-height: 100%;
    padding-bottom: 1px;
}

.content-code {
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #323f4c;
    border-color: #323f4c;
}

.content {
    position: relative;
    z-index: 30;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6,
.content p,
.content table,
.content aside,
.content dl,
.content ul,
.content ol,
.content .central-overflow-x {
    margin-right: 50%;
    padding: 0 28px;
    box-sizing: border-box;
    display: block;
    max-width: 680px;
}

.content .central-overflow-x {
    margin-right: calc(50% + 28px);
    margin-left: 28px;
    padding: 0;
    overflow-y: hidden;
    max-width: 100%;
    display: block;
}

.content p .central-overflow-x {
    margin-right: 0;
    margin-left: 0;
}

.break-word {
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.content ul,
.content ol {
    padding: 0 44px;
}

.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
    font-size: 15px;
    margin-top: 2.5em;
    margin-bottom: 0.8em;
    color: #034c8f;
    text-transform: uppercase;
}

.content h2 {
    font-size: 1.333em;
}

.content h4 {
    color: #00a8e3;
    margin-top: 0;
    text-transform: none;
    font-size: 14px;
    margin-bottom: 0.2em;
}

.content-page .content p,
.content-page .content pre {
    max-width: 680px;
}

.content pre,
.content blockquote {
    background-color: #323f4c;
    border-color: #323f4c;
    color: #fff;
    padding: 0 28px 2em;
    margin: 0;
    width: 50%;
    float: right;
    clear: right;
    box-sizing: border-box;
}

.content pre code, .content pre {
    font-size: 12px;
    line-height: 1.5;
}

.content blockquote,
.content pre,
.content pre code {
    padding-top: 0;
    margin-top: 0;
}

.content pre code {
    margin-top: -2em;
}

.content table {
    font-size: 0.825em;
    margin-bottom: 1.5em;
    border-collapse: collapse;
    border-spacing: 0;
}

.content table tr:last-child {
    border-bottom: 1px solid #ccc;
}

.content table th {
    font-size: 0.925em;
    padding: 5px 18px 5px 0;
    border-bottom: 1px solid #ccc;
    vertical-align: bottom;
    text-align: left;
    line-height: 1.6;
}

.content table td {
    padding: 5px 18px 5px 0;
    text-align: left;
    vertical-align: top;
    line-height: 1.6;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #777A7A;
}


/* burger-menu-icon
----------------------------------------------------------------------------------------*/
.burger-menu-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    position: absolute;
    right: 26px;
    top: 26px;
    display: none;
}

.burger-menu-icon .line {
    fill: none;
    stroke: #000;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.burger-menu-icon .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.burger-menu-icon .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
}

.burger-menu-icon .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

html.menu-opened .burger-menu-icon .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

html.menu-opened .burger-menu-icon .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
}

html.menu-opened .burger-menu-icon .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}


/* ONE CONTENT COLUMN VERSION
----------------------------------------------------------------------------------------*/

body.one-content-column-version .content h1,
body.one-content-column-version .content h2,
body.one-content-column-version .content h3,
body.one-content-column-version .content h4,
body.one-content-column-version .content h5,
body.one-content-column-version .content h6,
body.one-content-column-version .content p,
body.one-content-column-version .content table,
body.one-content-column-version .content ul,
body.one-content-column-version .content ol,
body.one-content-column-version .content aside,
body.one-content-column-version .content dl,
body.one-content-column-version .content ul,
body.one-content-column-version .content ol {
    margin-right: 0;
    max-width: 100%;
}

body.one-content-column-version .content-page .content p,
body.one-content-column-version .content-page .content pre {
    max-width: 100%;
}

body.one-content-column-version .content-page {
    background-color: #323f4c;
}

body.one-content-column-version .content h1:first-child,
body.one-content-column-version .content h2,
body.one-content-column-version .content h3,
body.one-content-column-version .content h4,
body.one-content-column-version .content h5,
body.one-content-column-version .content h6 {
    color: #59C3C3;
}

body.one-content-column-version p {
    color: #D6F0F0;
}

body.one-content-column-version .content table td {
    color: #D6F0F0;
}

body.one-content-column-version .content thead {
    color: #417179;
}

/* RESPONSIVE
----------------------------------------------------------------------------------------*/

@media only screen and (max-width:980px) {
    .content h1, .content h2, .content h3, .content h4, .content h5, .content h6, .content p, .content table, .content ul, .content ol, .content aside, .content dl, .content ul, .content ol {
        margin-right: 0;
    }

    .content .central-overflow-x {
        margin: 0;
        padding: 0 28px;
    }

    .content-code {
        display: none;
    }

    .content pre, .content blockquote {
        margin: 20px 0;
        padding: 28px;
        display: block;
        width: auto;
        float: none;
    }

    .content pre code {
        margin-top: 0;
    }
}

@media only screen and (max-width:680px) {
    html {
        scroll-padding-top: 83px;
    }

    html.menu-opened {
        overflow: hidden;
    }

    .left-menu {
        position: relative;
        width: auto;
    }

    .left-menu .content-menu {
        position: fixed;
        width: 400px;
        max-width: 90vw;
        z-index: 3;
        top: 0;
        bottom: 0;
        right: -405px;
        left: auto;
        background-color: #fff;
        margin: 0;
        overflow-x: hidden;
        padding-top: 83px;
        padding-bottom: 20px;
    }

    .left-menu .content-menu ul {
        position: relative;
    }

    .left-menu .mobile-menu-closer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background-color: rgba(50, 63, 76, .5);
        opacity: 0;
        visibility: hidden;
    }

    html.menu-opened .left-menu .mobile-menu-closer {
        opacity: 1;
        visibility: visible;
    }

    html.menu-opened .left-menu .content-menu {
        right: 0;
    }

    .left-menu .content-logo {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 4;
        background-color: #f4f5f8;
    }

    .content-logo .logo {
        margin-right: 65px;
    }

    .content-page {
        margin-left: 0;
        padding-top: 83px;
    }

    .burger-menu-icon {
        display: block;
    }
}

.cf:before, .cf:after {
    content: "";
    display: block;
}

.cf:after {
    clear: both;
}

.ie6 .cf {
    zoom: 1
}