/*\ ||||| SCROLL-BAR ||||| \*/
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(75, 89, 247);
}

/*\ ||||| HTML ||||| \*/
html.overflow {
    overflow: hidden;
}

/*\ ||||| BODY ||||| \*/
body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*\ ||||| NAVBAR ||||| \*/
.lp_navbar {
    background: rgb(16, 21, 34);
    height: 80px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0px;
    z-index: 999;
    width: unset !important;
}

.lp_navbar .navContainer {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
}

.lp_navbar .navContainer .logo {
    color: rgb(255, 255, 255);
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-transform: uppercase;
    display: flex;
    gap: 10px;
    font-weight: bold;
}

.lp_navbar .navContainer .logo img {
    height: 50px;
    width: 50px;
    border-radius: 50px;
}

.lp_navbar .navContainer ul {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    list-style: none;
    text-align: center;
}

.lp_navbar .navContainer li {
    height: 80px;
    border-bottom: 2px solid transparent;
    border-radius: 2px;
    display: flex;
}

.lp_navbar .navContainer ul li a {
    color: rgb(255, 255, 255);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.lp_navbar .nav_responsive a:hover {
    color: rgb(75, 89, 247);
    transition: color 0.3s ease-in-out,
}

.lp_navbar .navContainer button {
    border-radius: 4px;
    background: rgb(75, 89, 247);
    white-space: nowrap;
    padding: 10px 20px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
}

.lp_navbar .navContainer button:hover {
    background-color: rgb(42 53 175);
    transition: background-color 0.3s ease-in-out,
}

.lp_navbar .navbar-menu {
    display: none;
}

.lp_navbar .burger {
    display: none;
}

.lp_navbar .burger-menu-icon .line {
    fill: none;
    stroke: #fff;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.lp_navbar .burger-menu-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    vertical-align: middle;
    padding: 0;
    display: none;
    position: unset;
}

.lp_navbar .burger svg {
    height: 40px;
    width: 49px;
}

.burger-menu-icon .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.burger-menu-icon .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
}

.burger-menu-icon .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.burger.menu-opened .burger-menu-icon .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

.burger.menu-opened .burger-menu-icon .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
}

.burger.menu-opened .burger-menu-icon .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}


#navbar-menu {
    --navbar-height: 77px;
    position: fixed;
    top: var(--navbar-height);
    bottom: 0;
    transition: opacity 0.2s ease-in-out,
        visibility 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    left: 0;
    right: 0;
    display: none;
}

#navbar.opened #navbar-menu {
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
    visibility: visible;
}

.navbar-links {
    list-style-type: none;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    background: rgb(16, 21, 34);
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    right: 0;
    margin: 1.4rem;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

#navbar.opened .navbar-links {
    padding: 1em;
    max-height: none;
}

.navbar-item {
    margin: 0.4em;
    height: unset !important;
}

.navbar-link {
    color: #fff;
    transition: color 0.2s ease-in-out;
    text-decoration: none;
    display: flex;
    font-weight: 400;
    align-items: center;
    transition: background-color 0.2s ease-in-out,
        color 0.2s ease-in-out;
}

.navbar-link {
    justify-content: center;
    width: 100%;
    padding: 0.4em 0.8em;
    border-radius: 5px;
}

.navbar-link:focus,
.navbar-link:hover {
    background-color: rgb(75, 89, 247);
}

.navbar-link.blue {
    background-color: rgb(75, 89, 247);
}


@media only screen and (max-width: 920px) {
    .lp_navbar .navContainer {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media only screen and (max-width: 830px) {
    .lp_navbar .nav_responsive {
        display: none !important;
    }

    .lp_navbar .navbar-menu {
        display: block !important;
    }


    .lp_navbar .burger-menu-icon {
        display: block !important;
        padding: 2px !important;
    }

    .lp_navbar .burger {
        display: flex !important;
        align-items: center;
        color: #fff;
        cursor: pointer;
        padding: .5rem 1rem;
        text-decoration: none;
    }
}

/*\ ||||| FOOTER ||||| \*/
footer {
    background-color: rgb(16, 21, 34);
    color: rgb(255, 255, 255);
    padding: 4rem 0px 2rem;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

footer .copyright {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
}

footer .copyright img {
    height: 30px;
    width: 30px;
    border-radius: 50px;
}

footer .section_footer {
    background-color: rgb(16, 21, 34);
    padding: 4rem 0px 2rem;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 22px;
}

footer .footer_flex {
    display: flex;
}

footer .footer_position {
    width: 100%;
    max-width: 1000px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

footer .section_redirection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
}

footer .section_redirection h2 {
    margin-bottom: 16px;
}

footer .section_redirection a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    margin-bottom: 0.5rem;
    cursor: pointer;
    font-size: 18px;
    padding: 5px 5px 5px 0px;
}

footer .section_redirection a:hover {
    color: rgb(75, 89, 247);
    transition: color 0.3s ease-in-out,
}



@media only screen and (max-width: 790px) {
    footer .footer_position {
        flex-direction: column;
    }
}

@media only screen and (max-width: 465px) {
    footer .copyright {
        gap: 0;
        padding-left: 50px;
        padding-right: 50px;
    }
}

/*\ ||||| INDEX ||||| \*/

.lp-container {
    color: rgb(255, 255, 255);
    padding: 105px 0px;
    background: rgb(16, 21, 34);
}

.lp-container.white {
    background: rgb(255, 255, 255);
}

.lp-container.blue {
    background-color: rgb(75, 89, 247);
}

.lp-container.blue .middle {
    text-align: center;
}

.lp-container .position .middle .padding {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
}


.lp-container .position .left {
    max-width: 540px;
    padding-top: 0px;
    padding-bottom: 60px;
}

.lp-container .position .right {
    max-width: 540px;
    padding-top: 0px;
    padding-bottom: 60px;
}

.lp-container .section_title {
    color: rgb(169, 179, 193);
    font-size: 18px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    margin-bottom: 16px;
}

.lp-container h1 {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: rgb(247, 248, 250);
}

.lp-container.white h1 {
    color: rgb(28, 34, 55);
}

.lp-container.white p {
    color: rgb(28, 34, 55);
}

.lp-container p {
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: #cfd7e1;
}

.lp-container button {
    border-radius: 4px;
    background: rgb(75, 89, 247);
    white-space: nowrap;
    padding: 12px 64px;
    color: rgb(255, 255, 255);
    font-size: 20px;
    outline: none;
    border: none;
    cursor: pointer;
}

.lp-container button:hover {
    background-color: rgb(42 53 175);
    transition: background-color 0.3s ease-in-out,
}

.lp-container img {
    padding-right: 0px;
    border: 0px;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    max-height: 500px;
}

.lp-container .feature_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.lp-container .feature_list .feature {
    background-color: rgb(36, 36, 36);
    width: 280px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
}


.lp-container .feature_list .feature h1 {
    margin-bottom: 5px;
    font-size: 24px;
}

.lp-container .feature_list .feature i {
    padding: 15px;
    font-size: 50px;
    border-radius: 50px;
    background-color: rgb(75, 89, 247);
    margin-top: 20px;
    margin-bottom: 15px;
}

@media only screen and (max-width: 1230px) {
    .lp-container .padding {
        justify-content: center !important;
        gap: 30px;
        padding: 0px 30px;
    }

    .lp-container .padding.reverse {
        flex-direction: column-reverse !important;
    }
}

@media only screen and (max-width: 500px) {
    .lp-container {
        padding: 105px 10px !important;
        text-align: center;
    }
}

/*\ ||||| 404 NOT FOUND ||||| \*/
#notfound {
    position: relative;
    height: calc(100vh - 131px);
    background-color: #101522;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
}

.notfound .notfound-404 {
    position: relative;
    height: 180px;
    margin-bottom: 65px;
    z-index: -1;
}

.notfound p {
    color: white;
    font-weight: bolder;
    padding: 0px 0px 50px 0px;
}

.notfound .notfound-404 h2 {
    font-family: montserrat, sans-serif;
    position: absolute;
    left: 0;
    right: 0;
    top: 110px;
    font-size: 42px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 2px 0 #8400ff;
    letter-spacing: 13px;
    margin: 0;
}

.notfound .notfound-404 h1 {
    font-family: montserrat, sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 206px;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -12px;
    color: #030005;
    text-transform: uppercase;
    text-shadow: -1px -1px 0 #5c81ff, 1px 1px 0 #ffd700;
    letter-spacing: -20px;
}

.notfound a {
    font-family: montserrat, sans-serif;
    display: inline-block;
    text-transform: uppercase;
    color: #6a81ff;
    text-decoration: none;
    border: 2px solid;
    background: 0 0;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 700;
    -webkit-transition: .2s all;
    transition: .2s all;
}

/*\ ||||| FAQ ||||| \*/

section.faq {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
}

section.faq .section-title {
    margin-bottom: 50px;
}

section.faq .section-title h1 {
    font-size: 36px;
    text-align: center;
    color: rgb(75, 89, 247);
}

section.faq .question-box {
    box-sizing: content-box;
    position: relative;
    width: 500px;
    height: 50px;
    margin-bottom: 20px;
    padding: 15px 20px;
    border-radius: 5px;
    background: #ffffff;
    -webkit-box-shadow: 0 10px 10px #00000010;
    box-shadow: 0 10px 10px #00000010;
    overflow: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

section.faq .question-box.show {
    height: 150px;
}

section.faq .question-box .question {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

section.faq .question-box .question p {
    margin-block-start: 10px;
    font-size: 20px;
    color: #444c53;
    letter-spacing: 2px;
    font-weight: bold;
    max-width: none !important;
    line-height: unset !important;
    margin-bottom: revert !important;
}

section.faq .question-box .question .btn {
    position: relative;
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #cc9035;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
}

section.faq .question-box .question .btn:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

section.faq .question-box .question .btn div {
    position: absolute;
    width: 10px;
    height: 2px;
    margin: 5px;
    background: #cc9035;
}

section.faq .question-box .question .btn .rotate {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

section.faq .question-box .question .btn .rotate.minus {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

section.faq .question-box .answer {
    position: absolute;
    left: 25px;
    margin-top: 20px;
    padding: 0px 20px 10px 0px;
    max-width: 400px;
}

section.faq .question-box .answer p {
    font-size: 15px;
    font-weight: 500;
    color: #59636d;
    line-height: 17px;
    max-width: none !important;
    margin-bottom: 0 !important;
}

@media only screen and (max-width: 580px) {
    section.faq .question-box {
        width: 400px !important;
    }
}

@media only screen and (max-width: 480px) {
    section.faq .question-box {
        width: 300px !important;
    }

    section.faq .question-box .question p {
        margin-block-start: 0 !important;
    }
}



/*\ ||||| LEGALS ||||| \*/
.legals {
    padding: 50px 50px 50px 100px;
    background: rgb(222, 225, 255);
}

.legals h1, h2 {
    color: rgb(75, 89, 247);
}

.legals h1 {
    margin-block-end: 10px;
    margin-block-start: 10px;
}

.legals h2 {
    margin-block-end: 10px;
    margin-block-start: 10px;
}

.legals p {
    margin-block-end: 10px;
    margin-block-start: 10px;
}


.legals h4 {
    color: #101522;
    font-size: 20px;
    margin-block-end: 10px;
    margin-block-start: 10px;
}

@media only screen and (max-width: 1000px) {
    .legals {
        padding: 50px !important;
    }
}

@media only screen and (max-width: 500px) {
    .legals {
        padding: 50px 10px !important;
    }
}

/*\ ||||| COOKIES ||||| \*/

.cookie-container {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: -100%;
    z-index: 4;
    left: 0;
    right: 0;
    background: #2f3640;
    color: #f5f6fa;
    padding-left: 15px;
    box-shadow: 0 -2px 16px rgb(47 54 64 / 39%);
    transition: 400ms;
}

.cookie-container.active {
    bottom: 0
}

.cookie-container p {
    margin: 20px;
}

.cookie-container a {
    color: #4b76e8;
    font-weight: 700;
}

.cookie-btn {
    background: #4b76e8;
    border: 0;
    color: #f5f6fa;
    padding: 10px;
    font-size: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0px 10px;
}

/*\ ||||| LOGIN ||||| \*/

/** API CONTAINER **/
.api-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.api-container .api-content {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px #0000001a;
    max-width: max-content;
    padding: 30px;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.api-container .api-icon {
    font-size: 50px;
    color: #4caf50;
    margin-bottom: 20px;
}

.api-container .api-icon.error {
    color: #af4c4c;
}

.api-container .api-content h1 {
    font-size: 24px;
    color: #333;
    margin: 0;
}

.api-container .api-content p {
    font-size: 16px;
    color: #555;
    margin-top: 10px;
}

.api-container button {
    background-color: #1775f1;
    border: 1px solid;
    border-radius: 3px;
    color: #fff;
    margin-top: 10px;
    font-size: 15px;
    padding: 8px 25px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
}

.api-container button:hover {
    background-color: #3170c3;
}

.api-container button.btn-red {
    background-color: #f11717;
}

.api-container button.btn-red:hover {
    background-color: #c33131;
}

.api-container .api-buttons {
    display: flex;
    flex-direction: column;
}