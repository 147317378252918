:root {
    --grey: #F1F0F6;
    --dark-grey: #8D8D8D;
    --light: #fff;
    --dark: #000;
    --green: #81D43A;
    --light-green: #E3FFCB;
    --blue: #1775F1;
    --light-blue: #D0E4FF;
    --dark-blue: #0C5FCD;
    --red: #FC3B56;
    --blue-theme: #101522;
}

html {
    overflow-x: hidden;
}

body {
    background: #1f2129;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

h3 {
    font-weight: 100;
}


/* SIDEBAR */
#sidebar {
    position: fixed;
    max-width: 260px;
    width: 100%;
    background: var(--blue-theme);
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    transition: all .3s ease;
    z-index: 200;
}

#sidebar.hide {
    max-width: 60px;
}

/* #sidebar.hide:hover {
    max-width: 260px;
} */

#sidebar::-webkit-scrollbar {
    display: none;
}

#sidebar .brand {
    font-size: 24px;
    display: flex;
    align-items: center;
    height: 64px;
    font-weight: 700;
    color: var(--light);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    transition: all .3s ease;
    padding: 0 6px;
    text-transform: uppercase;
}

#sidebar .brand img {
    height: 48px;
    width: 20px;
    min-width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    border-radius: 50px;
}

#sidebar .icon {
    min-width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
}

#sidebar .icon-right {
    margin-left: auto;
    transition: all .3s ease;
}

#sidebar .side-menu {
    margin: 36px 0;
    padding: 0 20px;
    transition: all .3s ease;
}

#sidebar.hide .side-menu {
    padding: 0 6px;
}

#sidebar .side-menu a {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--light);
    padding: 12px 16px 12px 0;
    transition: all .3s ease;
    border-radius: 10px;
    margin: 4px 0;
    white-space: nowrap;
}

#sidebar .side-menu>li>a:hover {
    background: var(--grey);
    color: var(--blue-theme);
}

#sidebar .side-menu>li>a.active .icon-right {
    transform: rotateZ(90deg);
}

#sidebar .side-menu>li>a.active,
#sidebar .side-menu>li>a.active:hover {
    background: var(--blue);
    color: var(--light);
}

#sidebar .divider {
    margin-top: 24px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--dark-grey);
    transition: all .3s ease;
    white-space: nowrap;
}

#sidebar.hide .divider {
    text-align: center;
}

#sidebar .side-dropdown {
    padding-left: 54px;
    max-height: 0;
    overflow-y: hidden;
    transition: all .15s ease;
}

#sidebar .side-dropdown.show {
    max-height: 1000px;
}

#sidebar .side-dropdown a:hover {
    color: var(--blue);
}

#sidebar .ads {
    margin-top: 30px;
    color: white;
}

#sidebar.hide .ads {
    display: none;
}

#sidebar.hide:hover .ads {
    display: block;
}

#sidebar .ads .wrapper {
    background: var(--grey);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#sidebar .btn-upgrade {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    color: var(--light) !important;
    background: var(--blue) !important;
    transition: all .3s ease;
    border-radius: 5px;
    font-weight: 600;
    margin-bottom: 12px;
}

#sidebar .btn-upgrade:hover {
    background: var(--dark-blue);
}

.btn-premium {
    background: var(--blue);
    border-radius: 4px;
    white-space: nowrap;
    padding: 10px 30px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    transition: all .3s ease;
    outline: none;
    border: none;
    cursor: pointer;
}

.display_coins {
    background-color: #000;
    border-radius: 5px;
    color: gold;
    padding: 8px;
    font-weight: 700;
}



.btn-premium:hover {
    background: var(--dark-blue);
}

#sidebar .ads .wrapper p {
    font-size: 12px;
    color: var(--dark-grey);
    text-align: center;
}

#sidebar .ads .wrapper p span {
    font-weight: 700;
}


/* CONTENT */
#content {
    padding-top: 64px;
    left: 260px;
    position: relative;
    transition: all .3s ease;
    width: calc(100% - 260px);
}

#sidebar.hide+#content {
    width: calc(100% - 60px);
    left: 60px;
}

/*\ ||||| FORM ||||| \*/
main .form {
    margin-top: 10px;
    display: flex;
    grid-gap: 10px;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

main .form input {
    flex-grow: 1;
    padding: 10px 16px;
    border-radius: 5px;
    outline: none;
    background: var(--light);
    border: none;
    transition: all .3s ease;
    width: 350px;
}

main .form input:focus {
    box-shadow: 0 0 0 1px var(--blue), 0 0 0 4px var(--light-blue);
}

main .form .send_premium_form {
    display: flex;
    gap: 10px;
}

/*\ ||||| CLAIM ||||| \*/

.claim_div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

/*\ ||||| AUTO FARM ||||| \*/

.autofarm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*\ ||||| CUSTOM SELECT ||||| \*/
select {
    padding: 10px 40px;
    border-radius: 10px;
    background-color: #101522;
    color: white;
    text-align: center;
    margin-bottom: 5px;
}

select option {
    text-align: left;
    display: flex;
}

select:focus-visible {
    outline: none;
}

.form_select {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form_select .container span {
    color: #6a81ff;
}


/*\ ||||| CONTAINER ||||| \*/
.db-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 10px;
    color: var(--light);
}

.db-container.background {
    background-color: #1F2129;
    border-radius: 5px;
}

.db-container .guild_picture {
    position: relative;
    z-index: 2;
    /*
    border: 2px solid rgb(255 255 255);
    */
    filter: drop-shadow(rgba(0, 0, 0, 0.16) 0px 32px 72px);
    width: 80px;
    height: 80px;
    border-radius: 8px;
}


.db-container button {
    margin-top: 10px;
    background: var(--blue);
    border-radius: 4px;
    white-space: nowrap;
    padding: 8px 20px;
    color: rgb(255, 255, 255);
    transition: all .3s ease;
    outline: none;
    border: none;
    cursor: pointer;
}

.db-container button:hover {
    background: var(--dark-blue);
    transition: all .3s ease;
}


.db-container .btn-red {
    background-color: #f11717;
}

.db-container .btn-red:hover {
    background-color: #962525;
}

.db-container .display_guild_list_button {
    background: var(--blue);
    border-radius: 4px;
    white-space: nowrap;
    padding: 8px 20px;
    color: rgb(255, 255, 255);
    transition: all .3s ease;
    outline: none;
    border: none;
    cursor: pointer;
}

.display_guild_list_button.red {
    background-color: #f11717;
}

.display_guild_list_button.red:hover {
    background-color: #962525;
}

.db-container .display_guild_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.display_guild_list h2 {
    text-align: center;
}

/*\ ||||| SETTINGS PAGE ||||| \*/

.settings.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.settings .leftbox {
    display: flex;
    width: 100%;
    background: #1775f1;
    justify-content: center;
    border-radius: 5px;
}

.settings .nav {
    display: flex;
}

.settings .nav a {
    color: #baaaaa;
    display: block;
    font-size: 1.1em;
    list-style: none;
    padding: 10px 50px;
    transition: all .3s ease-in-out;
}

.settings .nav a:hover {
    color: #ffffff;
    cursor: pointer;
    transform: scale(1.2);
}

.settings .nav a.active {
    color: #ffffff;
}

.settings .rightbox {
    margin-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.settings .tabShow {
    transition: all .5s ease-in;
    display: none;
}

.settings .tabShow.active {
    display: block;
}

.settings .developer_redirect {
    display: flex;
    gap: 10px;
}

.settings h1 {
    color: var(--blue);
    font-size: 1.2rem;
    margin-top: 40px;
    margin-bottom: 35px;
}

.settings h2 {
    color: var(--light);
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    margin-left: 2px;
    margin-top: 10px;
}

.settings img {
    height: 80px;
    width: 80px;
    border-radius: 10px;
}

.settings .input {
    border: 0;
    border-bottom: 1px solid var(--blue);
    width: 80%;
    padding: 7px 5px;
    color: var(--light);
    outline: none;
}

.settings .input:disabled {
    background-color: #1f2129;
}

.settings p {
    border: 0;
    border-bottom: 1px solid var(--blue);
    width: 80%;
    padding: 7px 0;
    color: var(--light);
    outline: none;
}

.settings span {
    font-size: .7em;
    color: #777;
}

.settings .btn {
    text-transform: uppercase;
    font-size: 15px;
    border: 0;
    color: #fff;
    background: var(--blue);
    padding: 7px 15px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .2);
    cursor: pointer;
    margin-top: 15px;
    border-radius: 3px;
}

.settings .btn.red {
    background-color: #f11717;
}

.settings .btn.red:hover {
    background-color: #ed4545;
}

.settings .btn:hover {
    background-color: #3a89ef;
}

@media screen and (max-width: 500px) {
    .settings .nav a {
        padding: 10px 30px !important;
    }
}

/*\ ||||| HISTORY ||||| \*/

.history_logs {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 5px;
    width: 100%;
    align-items: center;
    text-align: center;
}

.history_logs p {
    color: var(--light);
}

body ul li input[type=radio] {
    position: absolute;
    left: -25px;
    top: 5px;
    opacity: 0;
    z-index: 9;
}

/*\ ||||| CUSTOM TOGGLE BUTTON ||||| \*/

.toggles-flex {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #ff512f;
    font-size: 20px;
}

.toggles {
    padding-left: 7.5px;
}

.toggles .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
    margin-top: 4px;
}

.toggles .switch input {
    display: none;
}

.toggles .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
}

.toggles .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
}

.toggles input:checked+.slider {
    background-color: #FF512F;
}

.toggles input:focus+.slider {
    box-shadow: 0 0 1px #FF512F;
}

.toggles input:checked+.slider:before {
    transform: translateX(22px);
}

.toggles .slider.round {
    border-radius: 34px;
}

.toggles .slider.round:before {
    border-radius: 50%;
}

/*\ ||||| MORE ||||| \*/
.span_informations {
    color: #1775f1;
}

/*\ ||||| CHECK ||||| \*/

.display_check {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.display_check img {
    border: 2px solid #fff;
    border-radius: 15px;
    -webkit-filter: drop-shadow(rgba(0, 0, 0, .16) 0 32px 72px);
    filter: drop-shadow(rgba(0, 0, 0, .16) 0 32px 72px);
    height: 80px;
    position: relative;
    width: 80px;
    z-index: 2;
}

.check_flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/*\ ||||| FARM ||||| \*/

.display_farm {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.display_farm img {
    border: 2px solid #fff;
    border-radius: 15px;
    -webkit-filter: drop-shadow(rgba(0, 0, 0, .16) 0 32px 72px);
    filter: drop-shadow(rgba(0, 0, 0, .16) 0 32px 72px);
    height: 80px;
    position: relative;
    width: 80px;
    z-index: 2;
}

/*\ ||||| MANAGE ||||| \*/

.ad_informations {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #272934;
    padding: 10px;
    border-radius: 10px;
    /*
    box-shadow: 10px 10px 10px 10px #ededed;
*/
}

.premium_p {
    color: #8174ff;
}

.ad_action {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.ad_action.row {
    flex-direction: row !important;
}

.ad_picture {
    height: 100px;
    width: 100px;
    border-radius: 10px;
}

.position_tips {
    padding: 10px 0px;
}

.position_tips_flex {
    display: flex;
    gap: 5px;
}

#add_bot {
    text-align: center;
}

#add_invite {
    text-align: center;
}

/*\ ||||| CIRCLE ANIMATION ||||| \*/

.circles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: calc(100vh);
    overflow: hidden;
    margin-block-start: 0;
    margin-block-end: 0;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

/*\ ||||| NAVBAR ||||| \*/
nav {
    background: var(--blue-theme);
    height: 64px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    grid-gap: 13px;
    position: fixed;
    top: 0;
    z-index: 100;
    left: 260px;
    transition: all .3s ease;
    width: calc(100% - 260px);
}

nav.hide {
    width: calc(100% - 60px);
    left: 60px;
}

nav.hide .toggle-sidebar {
    transform: rotate(90deg);
}

nav .toggle-sidebar {
    cursor: pointer;
    font-size: 30px;
    margin-right: auto;
    color: var(--light);
    transition: all .3s ease;
}

nav form {
    max-width: 400px;
    width: 100%;
    margin-right: auto;
}

nav .form-group {
    position: relative;
}

nav .form-group input {
    width: 350px;
    background: var(--grey);
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 10px 36px 10px 16px;
    transition: all .3s ease;
}

nav .form-group input:focus {
    box-shadow: 0 0 0 1px var(--blue), 0 0 0 4px var(--light-blue);
}

nav .form-group .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    color: var(--dark-grey);
}

nav .nav-link {
    position: relative;
}

nav .nav-link .icon {
    font-size: 18px;
    color: var(--dark);
}

nav .nav-link .badge {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--light);
    background: var(--red);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light);
    font-size: 10px;
    font-weight: 700;
}

nav .divider {
    width: 1px;
    background: var(--grey);
    height: 12px;
    display: block;
}

nav .profile {
    position: relative;
}

nav .profile img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

nav .profile .profile-link {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    background: var(--light);
    padding: 10px 0;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, .1);
    border-radius: 10px;
    width: 160px;
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease;
}

nav .profile .profile-link.show {
    opacity: 1;
    pointer-events: visible;
    top: 100%;
}

nav .profile .profile-link a {
    padding: 10px 16px;
    display: flex;
    grid-gap: 10px;
    font-size: 14px;
    color: var(--dark);
    align-items: center;
    transition: all .3s ease;
}

nav .profile .profile-link a:hover {
    background: var(--grey);
}

nav .support-link {
    font-size: 23px;
    display: flex;
    color: #17f166;
    align-items: center;
    transition: all .3s ease;
    cursor: pointer;
}

nav .support-link:hover {
    color: #00cb4a;
}

.sidebar-support-link {
    display: none !important;
}

@media (max-width: 700px) {
    nav .support-link  {
        display: none;
    }

    .sidebar-support-link {
        display: flex !important;
    }
}

/* NAVBAR */



/* MAIN */
main {
    width: 100%;
    padding: 24px 20px 20px 20px;
    background-color: #1F2129;
}

main .title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--light);
}

main .breadcrumbs {
    display: flex;
    grid-gap: 6px;
}

main .breadcrumbs li,
main .breadcrumbs li a {
    font-size: 14px;
}

main .breadcrumbs li a {
    color: var(--blue);
    font-weight: bold;
}

main .breadcrumbs li a.active,
main .breadcrumbs li.divider {
    color: var(--dark-grey);
    pointer-events: none;
}

main .info-data {
    margin-top: 36px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 20px;
}

main .info-data .card {
    padding: 20px;
    border-radius: 10px;
    background: var(--light);
    box-shadow: 4px 4px 16px rgba(0, 0, 0, .05);
}

main .card .head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

main .card .head h2 {
    font-size: 24px;
    font-weight: 600;
}

main .card .head p {
    font-size: 14px;
}

main .card .head .icon {
    font-size: 20px;
    color: var(--green);
}

main .card .head .icon.down {
    color: var(--red);
}

main .card .progress {
    display: block;
    margin-top: 24px;
    height: 10px;
    width: 100%;
    border-radius: 10px;
    background: var(--grey);
    overflow-y: hidden;
    position: relative;
    margin-bottom: 4px;
}

main .card .progress::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--blue);
    width: var(--value);
}

main .card .label {
    font-size: 14px;
    font-weight: 700;
}

main .data {
    display: flex;
    grid-gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
}

main .data .content-data {
    flex-grow: 1;
    flex-basis: 400px;
    padding: 20px;
    background: rgb(39 41 52);
    border-radius: 10px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, .1);
}

main .content-data .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

main .content-data .head .all {
    flex-direction: column;
    align-items: flex-start;
}

main .content-data .title {
    font-size: 20px;
    font-weight: 600;
    padding: 10px 5px;
    width: 100%;
    border-radius: 5px;
    background: #1775f1;
    color: #ffff;
    text-align: center;
    border-radius: 5px;
}

main .content-data .head .menu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

main .content-data .head .menu .icon {
    cursor: pointer;
}

main .content-data .head .menu-link {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    width: 140px;
    background: var(--light);
    border-radius: 10px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, .1);
    padding: 10px 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease;
}

main .content-data .head .menu-link.show {
    top: 100%;
    opacity: 1;
    pointer-events: visible;
}

main .content-data .head .menu-link a {
    display: block;
    padding: 6px 16px;
    font-size: 14px;
    color: var(--dark);
    transition: all .3s ease;
}

main .content-data .head .menu-link a:hover {
    background: var(--grey);
}

main .content-data .chart {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    scrollbar-width: none;
}

main .content-data .chart::-webkit-scrollbar {
    display: none;
}

main .chat-box {
    width: 100%;
    max-height: 360px;
    overflow-y: auto;
    scrollbar-width: none;
}

main .chat-box::-webkit-scrollbar {
    display: none;
}

main .chat-box .day {
    text-align: center;
    margin-bottom: 10px;
}

main .chat-box .day span {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 20px;
    background: var(--light-blue);
    color: var(--blue);
    font-size: 12px;
    font-weight: 600;
}

main .chat-box .msg img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;
}

main .chat-box .msg {
    display: flex;
    grid-gap: 6px;
    align-items: flex-start;
}

main .chat-box .profile .username {
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-right: 6px;
}

main .chat-box .profile .time {
    font-size: 12px;
    color: var(--dark-grey);
}

main .chat-box .chat p {
    font-size: 14px;
    padding: 6px 10px;
    display: inline-block;
    max-width: 400px;
    line-height: 150%;
}

main .chat-box .msg:not(.me) .chat p {
    border-radius: 0 5px 5px 5px;
    background: var(--blue);
    color: var(--light);
}

main .chat-box .msg.me {
    justify-content: flex-end;
}

main .chat-box .msg.me .profile {
    text-align: right;
}

main .chat-box .msg.me p {
    background: var(--grey);
    border-radius: 5px 0 5px 5px;
}

main form {
    margin-top: 6px;
}

main .form-group {
    width: 100%;
    display: flex;
    grid-gap: 10px;
}

main .form-group input {
    flex-grow: 1;
    padding: 10px 16px;
    border-radius: 5px;
    outline: none;
    background: var(--grey);
    border: none;
    transition: all .3s ease;
    width: 350px;
}

main .form-group input:focus {
    box-shadow: 0 0 0 1px var(--blue), 0 0 0 4px var(--light-blue);
}

main .btn-send {
    padding: 0 16px;
    background: var(--blue);
    border-radius: 5px;
    color: var(--light);
    cursor: pointer;
    border: none;
    transition: all .3s ease;
}

main .premium_s {
    color: #1775f1;
    font-weight: bold;
}

main .btn-send:hover {
    background: var(--dark-blue);
}

/* MAIN */
/* CONTENT */
@media screen and (max-width: 768px) {
    #content {
        position: relative;
        width: calc(100% - 60px);
        transition: all .3s ease;
    }

    nav .divider {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    main .form input {
        width: unset !important;
    }
}

/*\ ||||| CARD ||||| \*/

.cards-container-feature {
    display: flex;
    flex-wrap: wrap;
}

.cards-container-feature .card {
    float: left;
    margin: 5px 10px;
}

.cards-container-feature button {
    margin-top: 10px;
    background: var(--blue);
    border-radius: 4px;
    white-space: nowrap;
    padding: 8px 20px;
    color: rgb(255, 255, 255);
    transition: all .3s ease;
    outline: none;
    border: none;
    cursor: pointer;
}

.cards-container-feature button:hover {
    background: var(--dark-blue);
    transition: all .3s ease;
}

.cards-container-feature .card-one {
    position: relative;
    width: 300px;
    background: #fff;
    box-shadow: 0 10px 7px -5px rgba(0, 0, 0, 0.4);
}

.cards-container-feature .card-one header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #c8c;
}

.cards-container-feature .card-one header::before, .card-one header::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: inherit;
}

.cards-container-feature .card-one header::before {
    transform: skewY(-8deg);
    transform-origin: 100% 100%;
}

.cards-container-feature .card-one header::after {
    transform: skewY(8deg);
    transform-origin: 0 100%;
}

.cards-container-feature .card-one header .avatar {
    position: absolute;
    left: 50%;
    top: 30px;
    margin-left: -50px;
    z-index: 5;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    background: #ccc;
    border: 3px solid #fff;
}

.cards-container-feature .card-one header .avatar img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: auto;
}

.cards-container-feature .card-one h3 {
    position: relative;
    margin: 80px 0 30px;
    text-align: center;
}

.cards-container-feature .card-one h3::after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -15px;
    width: 30px;
    height: 1px;
    background: #000;
}

.cards-container-feature .card-one .desc {
    color: #777;
    line-height: 1.5;
    padding: 40px 10px;
    text-align: center;
}

.cards-container-feature .card-one .contacts {
    width: 200px;
    max-width: 100%;
    margin: 0 auto 3rem;
}

.cards-container-feature .card-one .contacts a {
    display: block;
    width: 33.333333%;
    float: left;
    text-align: center;
    color: #c8c;
}

.cards-container-feature .card-one .contacts a:hover {
    color: #333;
}

.cards-container-feature .card-one .contacts a:hover .fa::before {
    color: #fff;
}

.cards-container-feature .card-one .contacts a:hover .fa::after {
    width: 100%;
    height: 100%;
}

.cards-container-feature .card-one .contacts a .fa {
    position: relative;
    width: 40px;
    height: 40px;
    line-height: 39px;
    overflow: hidden;
    text-align: center;
    font-size: 1.3em;
}

.cards-container-feature .card-one .contacts a .fa:before {
    position: relative;
    z-index: 1;
}

.cards-container-feature .card-one .contacts a .fa::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
    background: #c8c;
    transition: width 0.3s, height 0.3s;
}

.cards-container-feature .card-one .contacts a:last-of-type .fa {
    line-height: 36px;
}

.cards-container-feature .card-one .footer_card {
    position: relative;
    padding: 1rem;
    background-color: #101522;
    text-align: center;
}

.cards-container-feature .card-one .footer_card a {
    padding: 0 1rem;
    color: #e2e2e2;
    transition: color 0.4s;
}

.cards-container-feature .card-one .footer_card a:hover {
    color: #c8c;
}

.cards-container-feature .card-one .footer_card::before {
    content: "";
    position: absolute;
    top: -27px;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-bottom-color: #6573d0;
}

.cards-container-feature .card-two {
    position: relative;
    width: 300px;
    background: #1f2129;
    box-shadow: 0 10px 7px -5px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .cards-container-feature .card-two {
        width: 240px !important;
    }
}

.cards-container-feature .card-two header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #c8c;
}

.cards-container-feature .card-two header::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: inherit;
    transform: skewY(8deg);
    transform-origin: 0 100%;
}

.cards-container-feature .card-two header .avatar {
    position: absolute;
    left: 50%;
    top: 30px;
    margin-left: -50px;
    z-index: 5;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    background: #ccc;
    border: 3px solid #fff;
}

.cards-container-feature .card-two header .avatar img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: auto;
}

.cards-container-feature .card-two h3 {
    position: relative;
    margin: 30px 0 30px;
    text-align: center;
    color: var(--light);
}

.cards-container-feature .card-two h3::after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -15px;
    width: 30px;
    height: 1px;
    background: var(--light);
}

.cards-container-feature .card-two .desc {
    padding: 0 1rem 2rem;
    text-align: center;
    line-height: 1.5;
    color: #d1cccc;
    height: 270px;
}


@media only screen and (max-width: 500px) {
    .cards-container-feature .card-two .desc {
        height: 300px !important;
        padding: unset !important;
    }

}

.cards-container-feature .card-two .contacts {
    width: 200px;
    max-width: 100%;
    margin: 0 auto 3.5rem;
}

.cards-container-feature .card-two .contacts a {
    display: block;
    width: 33.333333%;
    float: left;
    text-align: center;
    color: #c8c;
}

.cards-container-feature .card-two .contacts a:hover {
    color: #333;
}

.cards-container-feature .card-two .contacts a:hover .fa::before {
    color: #fff;
}

.cards-container-feature .card-two .contacts a:hover .fa::after {
    top: 0;
}

.cards-container-feature .card-two .contacts a .fa {
    position: relative;
    width: 40px;
    height: 40px;
    line-height: 39px;
    overflow: hidden;
    text-align: center;
    border: 2px solid #c8c;
    border-radius: 50%;
}

.cards-container-feature .card-two .contacts a .fa:before {
    position: relative;
    z-index: 1;
}

.cards-container-feature .card-two .contacts a .fa::after {
    content: "";
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100%;
    transition: top 0.3s;
    background: #c8c;
}

.cards-container-feature .card-two .contacts a:last-of-type .fa {
    line-height: 36px;
}

.cards-container-feature .card-two .footer_card {
    position: relative;
    height: 42px;
    background-color: #101522;
    text-align: center;
}

.cards-container-feature .card-two .card-redirection {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.cards-container-feature .card-two .footer_card p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

.cards-container-feature .card-two .footer_card p a {
    padding: 0 1rem;
    color: #e2e2e2;
    transition: color 0.4s;
}

.cards-container-feature .card-two .footer_card p a:hover {
    color: #c8c;
}

.cards-container-feature .card-two .footer_card::before {
    z-index: 0;
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    background: inherit;
    transform: skewY(8deg);
    transform-origin: 100%;
}

/*** RESPONSIVE ***/
@media only screen and (max-width: 810px) {
    .cards-container-feature .card {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-width: 420px) {
    .db-container .display_guild_list {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 6px;
    }
}

/*\ ||||| MODAL ||||| \*/
.modal-box .show-modal {
    color: #fff;
    background-color: #FE3267;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 10px 25px 5px;
    margin: 80px auto 0;
    border-radius: 5px;
    border: none;
    outline: none;
    display: block;
    transition: all 0.3s ease 0s;
}

.modal-box .show-modal:hover {
    color: #fff;
    box-shadow: 0 0 10px #000 inset;
    border: none;
    outline: none;
    text-decoration: none;
}

.modal-backdrop.in {
    opacity: 0;
}

.modal-box .modal {
    top: 70px !important;
}

.modal-box .modal-dialog {
    width: 400px;
    margin: 30px auto 10px;
}

.modal-box .modal-dialog .modal-content {
    background: #101522;
    text-align: center;
    border: none;
    border-top: 7px solid #FE3267;
    border-radius: 5px;
}

.modal-box .modal-dialog .modal-content .close {
    color: #fff;
    background-color: rgba(193, 193, 193, .3);
    font-size: 25px;
    text-shadow: 0 1px 0 #fff;
    line-height: 35px;
    height: 30px;
    width: 30px;
    opacity: 0.5;
    border-radius: 50%;
    position: absolute;
    left: auto;
    right: 3px;
    top: 3px;
    z-index: 1;
    transition: all 0.3s;
}

.modal-box .modal-dialog .modal-content .close:hover {
    color: #fff;
    opacity: .75;
}

.modal-box .modal-dialog .modal-content .modal-body {
    padding: 30px 20px !important;
}

.modal-box .modal-dialog .modal-content .modal-body .icon {
    color: #fff;
    background: #FE3267;
    font-size: 50px;
    line-height: 95px;
    height: 80px;
    width: 80px;
    margin: 0 auto 20px;
    border-radius: 6px;
}

.modal-box .modal-dialog .modal-content .modal-body .title {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 10px 0;
}

.modal-box .modal-dialog .modal-content .modal-body .title i.fa {
    color: #28304e;
    background: #19B5FE;
    font-size: 15px;
    line-height: 25px;
    width: 25px;
    height: 25px;
    margin: 0 6px 0 0;
    border-radius: 20px;
    transform: translateY(-6px);
}

.modal-box .modal-dialog .modal-content .modal-body .description {
    color: rgba(255, 255, 255, .5);
    font-size: 15px;
    margin: 0 0 15px;
}

.modal-box .modal-dialog .modal-content .modal-body .subscribe {
    color: #fff;
    background-color: #0c9;
    font-size: 16px;
    text-transform: uppercase;
    padding: 12px 18px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    display: inline-block;
    transition: all 0.3s;
}

.modal-box .modal-dialog .modal-content .modal-body .subscribe:hover {
    background-color: #017a5c;
}

.modal-box .modal-dialog .modal-content .modal-body .subscribe.deny {
    background-color: #e74c3c;
}

.modal-box .modal-dialog .modal-content .modal-body .subscribe.deny:hover {
    background-color: #7f2a20;
}

@media only screen and (max-width: 576px) {
    .modal-dialog {
        width: 93% !important;
    }

    .modal-box .modal-dialog .modal-content .modal-body .title {
        font-size: 23px;
    }

    .modal-box .modal-dialog .modal-content .modal-body .title i.fa {
        transform: translateY(-3px);
    }
}

.modal-body .input {
    border: 0;
    border-bottom: 1px solid var(--blue);
    color: #070707;
    outline: none;
    padding: 7px 20px;
    width: 80%;
    text-align: center;
    border-radius: 5px;
}

.modal-body input:disabled {
    background-color: white;
}

.modal-body .title_input {
    color: white;
    padding: 10px;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

/*\ ||||| LEADERBOARD ||||| \*/

.leaderboard {
    display: flex;
    justify-content: center;
    margin: auto;
    border-radius: 10px;
}

.leaderboard-triple {
    border-radius: 10px;
    display: flex;
    height: auto;
    margin: 10px;
    justify-content: space-evenly;
    padding: 10px;
}

.all-triple {
    position: relative;
    width: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #00ffba;
    font-size: 2rem;
    font-family: sans-serif;
}

.all-triple img {
    background: #fff;
    border-radius: 50%;
}

.first {
    z-index: 2;
    color: #FFD700;
}

.first img {
    width: 180px;
    height: 180px;
    border: 5px solid #FFD700;
    box-shadow: 0 0 20px #FFD700;
}

.second {
    font-size: 1.6rem;
    color: #C0C0C0;
}

.second img {
    width: 125px;
    height: 125px;
    border: 5px solid #C0C0C0;
    box-shadow: 0 0 20px #C0C0C0;
}

.third {
    font-size: 1.4rem;
    color: #cd7f32;
}

.third img {
    width: 110px;
    height: 110px;
    border: 5px solid #cd7f32;
    box-shadow: 0 0 20px #cd7f32;
}

.leaderboard-content {
    width: 100%;
    padding-top: 10px;
}

.leaderboard-body.titletable {
    background-color: var(--blue);
}

.leaderboard-body {
    background-color: var(--light-blue);
    border-radius: 10px;
    display: flex;
    margin: 10px;
    overflow: hidden;
    padding: 10px;
    justify-content: space-between;
}

.user-title p {
    color: white;
    font-size: 24px;
    font-weight: bold;
    box-shadow: 0 5px #fff;
}

.leaderboard-user-count-title p {
    text-align: left;
}

.leaderboard-user-name-title p {
    text-align: left;
}

.leaderboard-user-point-title p {
    text-align: right;
}

.leaderboard-user-SLA-title p {
    text-align: center;
}

.leaderboard-user-country-title p {
    text-align: right;
}

.user-info p {
    font-size: 1rem;
    font-family: sans-serif;
    color: var(--dark);
}

.leaderboard-user-count p {
    text-align: left;
}

.leaderboard-user-name p {
    text-align: left;
}

.leaderboard-user-point p {
    text-align: right;
}

.leaderboard-user-SLA p {
    text-align: center;
}

.leaderboard-user-country p {
    text-align: right;
}

@media (max-width:950px) {
    .first img {
        height: 130px;
        width: 130px;
    }

    .third img {
        height: 90px;
        width: 90px;
    }

    .second img {
        height: 105px;
        width: 105px;
    }

    .select_leaderboard select {
        padding: 10px 20px !important;
    }
}

@media (max-width:600px) {
    .first img {
        height: 110px;
        width: 110px;
    }

    .third img {
        height: 70px;
        width: 70px;
    }

    .second img {
        height: 85px;
        width: 85px;
    }
}

@media (max-width:500px) {
    .first img {
        height: 80px;
        width: 80px;
    }

    .third img {
        height: 50px;
        width: 50px;
    }

    .second img {
        height: 55px;
        width: 55px;
    }

    .leaderboard-triple {
        margin: 0;
        padding: 0;
    }

    .leaderboard-triple p {
        font-size: 20px !important;
    }

    main {
        padding: 24px 10px 20px;
    }

    main .data .content-data {
        background: #272934;
        border-radius: 10px;
        box-shadow: 4px 4px 16px rgba(0, 0, 0, .1);
        flex-basis: 400px;
        flex-grow: 1;
        padding: 20px 8px;
    }
}

@media (max-width:540px) {
    .user-title p {
        font-size: 0.8rem;
    }

    .user-info p {
        font-size: 0.8rem;
    }
}


.rewards_affiliation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 50px 10px;
    text-align: center;
}

.rewards_affiliation p {
    color: var(--light);
}

.rewards_affiliation h2 {
    color: #4b59f7;
}

.rewards_affiliation button {
    margin-top: 10px;
    background: var(--blue);
    border-radius: 4px;
    white-space: nowrap;
    padding: 8px 20px;
    color: rgb(255, 255, 255);
    transition: all .3s ease;
    outline: none;
    border: none;
    cursor: pointer;
}

.rewards_affiliation button:hover {
    background: var(--dark-blue);
    transition: all .3s ease;
}

.affiliation_chart tspan {
    fill: white;
}

/*\ ||||| LOADING DOTS ||||| \*/
body.login-page {
    background-color: #101522;
}

.dots-container {
    background: #101522;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.dots {
    animation: bounce 1.5s infinite linear;
    background: #FFF;
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    text-align: center;
    width: 20px;
}

.dots:nth-child(1) {
    animation-delay: .2s;
}

.dots:nth-child(2) {
    animation-delay: .4s;
}

.dots:nth-child(3) {
    animation-delay: .6s;
}

.dots:nth-child(4) {
    animation-delay: .8s;
}

.dots:nth-child(5) {
    animation-delay: 1s;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    15% {
        transform: translateY(-15px);
    }

    30% {
        transform: translateY(0);
    }
}

/*\ ||||| WARNING / INFO MESSAGE ||||| \*/

.position_info_msg {
    display: flex;
    gap: 8px;
    align-items: baseline;
}

.position_info_msg>div {
    display: flex;
    gap: 5px;
}

.info-msg,
.success-msg,
.warning-msg,
.error-msg {
    margin: 5px 0;
    padding: 10px;
    border-radius: 3px 3px 3px 3px;
}

.info-msg {
    display: flex;
    gap: 10px;
    background-color: #bef;
    color: #059;
}

.success-msg {
    color: #270;
    background-color: #DFF2BF;
}

.warning-msg {
    color: #9F6000;
    background-color: #FEEFB3;
}

.error-msg {
    color: #D8000C;
    background-color: #FFBABA;
}

/** PACK PREMIUM /**/
.packs {
    background-color: #101522;
    border: 1px solid #1775f1;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
}

.packs h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: white;
}

.packs-description {
    margin-bottom: 20px;
    font-size: 1rem;
    color: white;
}

.packs-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.packs-item {
    background: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    margin: 10px;
    width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.packs-item:hover {
    transform: scale(1.05);
}

.packs-item h4 {
    margin: 0;
    font-size: 1.2rem;
    color: #222;
}

.packs-item p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #555;
}

.packs-item button {
    margin-top: 10px;
    padding: 10px 15px;
    font-size: 1rem;
    color: white;
    background-color: #12af57;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.packs-item button:hover {
    background-color: #0e8e47;
}

@media (max-width: 550px) {
    .packs-item {
        width: 170px;
    }
}