* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul {
    margin-block-start: 0;
    margin-block-end: 0;
}

